import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { cancelEditStep, editStep } from '@entities/portfolio';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { ReactComponent as UpdateIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import { ReactComponent as EditIcon } from '@icons/wolfkit-light/pencil-light.svg';
import { IsDefined } from '@utils/js-ts';
import { BodySemiBold } from '@src/components/styled/Typography';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import Button from '@shared/ui/buttons/Button';
import Icon from '@shared/ui/icons/Icon';
import ErrorMessage from '@shared/ui/errors/ErrorMessage';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
const getDisabledProps = (disabled) => disabled ? ({
    opacity: 0.5,
    userSelect: 'none',
    '& *': {
        cursor: 'default!important',
    }
}) : ({});
const Container = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'completelyDisabled' })(props => (Object.assign({ height: 'auto', gap: props.theme.spacing_sizes.m }, getDisabledProps(props.completelyDisabled))));
const TitleContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const ButtonsContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const CollapsedContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'error' })(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.xs,
    padding: props.theme.spacing_sizes.m,
    background: props.error ? props.theme.customColors.container.errorLight :
        props.theme.customColors.menu.item.select,
    borderRadius: props.theme.shape.borderRadius,
}));
const IconStyled = styled(Icon)(() => ({
    cursor: 'pointer',
    flexGrow: 0,
    flexShrink: 0,
}));
const BidToQueueStep = ({ type, stepNumber, title, tooltipText = undefined, loading = false, disabled = false, // TODO: rename to specify disable state for buttons only
completelyDisabled = false, collapsedContent = undefined, children = undefined, errorMessage = '', onAction = () => { }, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { completedSteps, stepsInEdit, } = useAppSelector(state => state.portfolioSubscription);
    const isCollapsed = useMemo(() => completedSteps.includes(type) && !stepsInEdit.includes(type), [type, completedSteps, stepsInEdit]);
    const isInEdit = useMemo(() => completedSteps.includes(type) && stepsInEdit.includes(type), [type, completedSteps, stepsInEdit]);
    const onEdit = () => {
        dispatch(editStep(type));
        onAction('edit');
    };
    const onContinue = () => {
        onAction('continue');
    };
    const onUpdate = () => {
        onAction('update');
    };
    const onCancel = () => {
        dispatch(cancelEditStep(type));
        onAction('cancel');
    };
    const onContainerClick = (event) => {
        if (completelyDisabled) {
            event.stopPropagation();
        }
    };
    const onContainerMouseOver = (event) => {
        if (completelyDisabled) {
            event.stopPropagation();
        }
    };
    return (_jsxs(Container, { completelyDisabled: completelyDisabled, onClickCapture: onContainerClick, onMouseOverCapture: onContainerMouseOver, children: [_jsxs(TitleContainer, { children: [_jsx(BodySemiBold, { color: theme.palette.text.secondary, children: `${t('portfolio.subscription.step', { ns: 'translation' })} ${stepNumber}.` }), _jsx(BodySemiBold, { children: title }), IsDefined(tooltipText) && (_jsx(QuestionMarkTooltip, { title: tooltipText, placement: 'top-start', arrow: true }))] }), isCollapsed && (_jsxs(_Fragment, { children: [_jsxs(CollapsedContainer, { error: !!errorMessage, children: [collapsedContent, _jsx(IconStyled, { size: 24, color: theme.palette.primary.main, onClick: onEdit, IconComponent: EditIcon })] }), IsDefined(errorMessage) &&
                        errorMessage !== '' && (_jsx(ErrorMessage, { errorMessage: errorMessage }))] })), !isCollapsed && (_jsxs(_Fragment, { children: [children, _jsxs(ButtonsContainer, { children: [isInEdit && (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', startIcon: UpdateIcon, disabled: disabled || loading, isLoading: loading, onClick: onUpdate, fullWidth: true, children: t('actions.update', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', disabled: loading, onClick: onCancel, fullWidth: true, children: t('actions.cancel', { ns: 'common' }) })] })), !isInEdit && (_jsx(Button, { variant: 'filled', color: 'success', size: 'large', disabled: disabled || loading, isLoading: loading, onClick: onContinue, fullWidth: true, children: t('actions.continue', { ns: 'common' }) }))] })] }))] }));
};
export default BidToQueueStep;
